var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-text-field',{attrs:{"autofocus":!_vm.$route.params.id,"rules":[
      _vm.$global.rules.required() ],"label":"Name","outlined":""},model:{value:(_vm.lesson_.name),callback:function ($$v) {_vm.$set(_vm.lesson_, "name", $$v)},expression:"lesson_.name"}}),_c('v-text-field',{attrs:{"rules":[
      _vm.$global.rules.required() ],"label":"Translation","outlined":""},model:{value:(_vm.lesson_.translation),callback:function ($$v) {_vm.$set(_vm.lesson_, "translation", $$v)},expression:"lesson_.translation"}}),_c('v-select',{attrs:{"items":_vm.lessonTypes,"rules":[
      _vm.$global.rules.required() ],"label":"Type","outlined":""},model:{value:(_vm.lesson_.type),callback:function ($$v) {_vm.$set(_vm.lesson_, "type", $$v)},expression:"lesson_.type"}}),_c('v-data-table',{attrs:{"items":_vm.lesson.typeDataIds,"headers":_vm.headers,"options":_vm.options},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"body",fn:function(ref){
    var pagination = ref.pagination;
return [_c('tbody',[_vm._l((_vm.lesson.typeDataIds.slice((pagination.page - 1) * pagination.itemsPerPage, pagination.page * pagination.itemsPerPage)),function(item,i){return _c('tr',{key:("lessonIds-" + i)},[_c('td',[_c('v-row',{attrs:{"no-gutters":""}},[(_vm.lesson.typeDataIds.length > 1)?_c('v-row',{staticClass:"flex-column flex-grow-0",attrs:{"no-gutters":""}},[_c('v-btn',{attrs:{"disabled":(pagination.page - 1) * pagination.itemsPerPage + i === 0,"text":"","color":"grey darken-1","width":"19","height":"19","min-width":"0"},on:{"click":function($event){_vm.moveDataIdsUp((pagination.page - 1) * pagination.itemsPerPage + i)}}},[_c('v-icon',[_vm._v("mdi-chevron-up")])],1),_c('v-btn',{attrs:{"disabled":(pagination.page - 1) * pagination.itemsPerPage + i === _vm.lesson.typeDataIds.length - 1,"text":"","color":"grey darken-1","width":"19","height":"19","min-width":"0"},on:{"click":function($event){_vm.moveDataIdsDown((pagination.page - 1) * pagination.itemsPerPage + i)}}},[_c('v-icon',[_vm._v("mdi-chevron-down")])],1)],1):_vm._e(),_c('v-select',{ref:"typeDataIds",refInFor:true,attrs:{"label":"Data ID","single-line":"","dense":"","flat":"","solo":"","hide-details":"","background-color":"grey lighten-4","item-value":function (item) { return item.id; },"items":_vm.items,"rules":[
                  _vm.$global.rules.required() ]},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                var lesson = ref.item;
return [(_vm.lesson_.type === "vocabulary")?_c('div',[_c('span',{},[_vm._v(_vm._s(lesson.text)+" - ")]),_c('span',{staticClass:"grey--text"},[_vm._v(_vm._s(lesson.translations[0]))]),_c('span',{staticClass:"grey--text overline"},[_vm._v(" ("+_vm._s(lesson.id)+")")])]):(_vm.lesson_.type === "grammar")?_c('div',[_c('span',{},[_vm._v(_vm._s(lesson.text)+" - ")]),_c('span',{staticClass:"grey--text"},[_vm._v(_vm._s(lesson.translation))]),_c('span',{staticClass:"grey--text overline"},[_vm._v(" ("+_vm._s(lesson.id)+")")])]):_vm._e()]}},{key:"item",fn:function(ref){
                var lesson = ref.item;
return [(_vm.lesson_.type === "vocabulary")?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(lesson.text)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(lesson.translations[0])+" ")])],1),_c('v-list-item-action',[_c('v-list-item-title',{staticClass:"overline"},[_vm._v(" "+_vm._s(lesson.id)+" ")])],1)],1):_vm._e(),(_vm.lesson_.type === "grammar")?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(lesson.text)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(lesson.translation)+" ")])],1),_c('v-list-item-action',[_c('v-list-item-title',{staticClass:"overline"},[_vm._v(" "+_vm._s(lesson.id)+" ")])],1)],1):_vm._e()]}}],null,true),model:{value:(_vm.lesson_.typeDataIds[(pagination.page - 1) * pagination.itemsPerPage + i]),callback:function ($$v) {_vm.$set(_vm.lesson_.typeDataIds, (pagination.page - 1) * pagination.itemsPerPage + i, $$v)},expression:"lesson_.typeDataIds[(pagination.page - 1) * pagination.itemsPerPage + i]"}}),(_vm.lesson.typeDataIds.length > 1)?_c('v-btn',{staticClass:"ml-4",attrs:{"icon":""},on:{"click":function($event){_vm.lesson_.typeDataIds.splice((pagination.page - 1) * pagination.itemsPerPage + i, 1)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e()],1)],1)])}),_c('tr',[_c('td',[_c('v-btn',{attrs:{"text":"","block":"","color":"accent"},on:{"click":function($event){return _vm.addDataId()}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" Add more ")],1)],1)])],2)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }