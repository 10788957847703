






































































































































































import Vue, {
  VueConstructor,
} from 'vue'
import mixins from 'vue-typed-mixins'
import {
  PropValidator,
} from 'vue/types/options'
import {
  Lesson, LessonType,
} from '@/types/lesson'
import {
  newLesson,
} from '@/util/lesson'
import {
  DataTableHeader,
} from 'vuetify'
import {
  VuetifySelectListItem,
  VuetifyTextField,
} from '@/plugins/vuetify/types'
import {
  grammarStore,
  wordStore,
} from '@/store'
import {
  Grammar,
} from '@/types/grammar'
import {
  Word,
} from '@/types/word'
import {
  Id,
} from '@/types/base'

export default mixins(Vue as VueConstructor<Vue & {
  $refs: {
    typeDataIds: VuetifyTextField[]
  }
}>)
  .extend({
    props: {
      lesson: {
        default: newLesson(),
        type: Object,
      } as PropValidator<Lesson>,
    },
    data: () => ({
      headers: [
        {
          value: `typeDataIds`,
          text: `Type Data IDs`,
          sortable: false,
        },
      ] as DataTableHeader[],
      lessonTypes: [
        {
          text: `Grammar`,
          value: `grammar`,
        },
        {
          text: `Vocabulary`,
          value: `vocabulary`,
        },
      ] as VuetifySelectListItem<LessonType>[],
      lesson_: newLesson(),
      options: {
        page: 0,
        itemsPerPage: 10,
      },
    }),
    computed: {
      grammars (): (Grammar & Id)[] {
        const grammars = grammarStore.data
        grammars.sort((a, b) => a.text.localeCompare(b.text))
        return grammars
      },
      words (): (Word & Id)[] {
        const words = wordStore.data
        words.sort((a, b) => a.text.localeCompare(b.text))
        return words
      },
      items (): any[] {
        switch (this.lesson_.type) {
        case `grammar`: return this.grammars
        case `vocabulary`: return this.words
        }
        return [
        ]
      },
    },
    watch: {
      lesson_: {
        deep: true,
        handler (lesson: Lesson) {
          this.$emit(`update:lesson`, lesson)
        },
      },
      lesson: {
        deep: true,
        immediate: true,
        handler (lesson: Lesson) {
          this.lesson_ = lesson
        },
      },
      'lesson_.type': {
        immediate: true,
        handler (type: string) {
          this.lesson_.typeDataIds = [
            ``,
          ]
          switch (type) {
          case `grammar`:
            grammarStore.getAll()
            break
          case `vocabulary`:
            wordStore.getAll()
            break
          }
        },
      },
    },
    methods: {
      async addDataId () {
        this.lesson_.typeDataIds.push(``)
        this.options.page = Math.ceil(this.lesson_.typeDataIds.length / this.options.itemsPerPage)
        await this.$nextTick()
        const typeDataIds = this.$refs.typeDataIds
        typeDataIds[typeDataIds.length - 1].focus()
      },
      async moveDataIdsUp (index: number) {
        const {
          typeDataIds,
        } = this.lesson
        const typeDataId = typeDataIds.splice(index, 1)[0]
        typeDataIds.splice(index - 1, 0, typeDataId)
      },
      async moveDataIdsDown (index: number) {
        const {
          typeDataIds,
        } = this.lesson
        const typeDataId = typeDataIds.splice(index, 1)[0]
        typeDataIds.splice(index + 1, 0, typeDataId)
      },
    },
  })
