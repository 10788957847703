import {
  Lesson, LessonType,
} from '@/types/lesson'

export const newLesson = (): Lesson => ({
  name: ``,
  translation: ``,
  type: `vocabulary`,
  typeDataIds: [
    ``,
  ],
})

export const getLessonColor = (type: LessonType) => {
  switch (type) {
  case `grammar`: return `#FFEB3B`
  case `vocabulary`: return `#F44336`
  }
}

export const getLessonIcon = (type: LessonType) => {
  switch (type) {
  case `grammar`: return `mdi-format-list-bulleted-triangle`
  case `vocabulary`: return `mdi-format-color-text`
  }
}
